import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, Signal, WritableSignal, computed, inject, signal } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CommonModule, Location, isPlatformBrowser } from '@angular/common';
import { ProductsService } from '../../product/products.service';
import { SignalsStoreService } from '../signals-store.service';
import { formatDateToReadableString } from '../utils/formatting';
import { DeliveriesService } from '../../settings/account/deliveries/deliveries.service';
import { DeliveryInformation } from '../../settings/account/deliveries/intarfaces';
import { MatTabsModule } from '@angular/material/tabs';
import { StockService } from '../../stock/stock.service';
import { OrderService } from '../order.service';
import { NotificationService } from '../notification/notification.service';
import { combineLatest, of, switchMap, tap } from 'rxjs';
import { LocalStorageService } from '../local-storage.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { delay, filter, map } from 'rxjs/operators';
import { arrayToMap, isANoDeliveryDayUser, isANoPaymentMethodUser, unableOperationMessage } from '../common/utils';
import { AddableProductCard } from '../product-card/addable-product-card/addable-product-card.component';
import { Session } from '../types/session.type';
import { MatProgressBar } from '@angular/material/progress-bar';
import { BundleEditionTypes, BundleEditionType, OrderResponse } from '../types/order.type';
import { LOCALSTORAGE_KEYS } from '../constants/databases';
import { ResolutionService } from '../resolution.service';
import { ModalContentService } from '../modal-content/modal-content.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatInputModule } from '@angular/material/input';
import { BundleItems, Product } from '../../product/product.types';

export enum CUSTOM_BOX_FLOW {
  ORDER = 'ORDER',
  PRODUCT = 'PRODUCT',
  SHOP = 'SHOP',
  SIGNUP = 'SIGNUP',
}

@Component({
  selector: 'app-custom-box',
  standalone: true,
  templateUrl: './custom-box.component.html',
  styleUrl: './custom-box.component.scss',
  imports: [
    CommonModule,
    MatSelectModule,
    MatInputModule,
    MatTabsModule,
    AddableProductCard,
    MatProgressBar,
    RouterLink
  ],
})
export class CustomBoxComponent implements OnInit, OnDestroy {
  signalStoreService = inject(SignalsStoreService);
  private deliveriesService = inject(DeliveriesService);
  private notificationService = inject(NotificationService);
  private orderService = inject(OrderService);
  private productsService = inject(ProductsService);
  private location = inject(Location);
  private localStorageService = inject(LocalStorageService);
  private signalsStoreService = inject(SignalsStoreService);
  private stockService = inject(StockService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private resolutionService = inject(ResolutionService);
  private modalContentService = inject(ModalContentService);
  private activeModal = inject(NgbModal);

  isMobile = computed(() => this.resolutionService.isMobile());

  stock = computed(() => this.stockService.stockSignal());
  stock$ = toObservable(this.stockService.stockSignal);
  firebaseOrder$ = toObservable(this.signalsStoreService.firebaseOrder);
  odooOrder$ = toObservable(this.orderService.odooOrder);
  productSignal$ = toObservable(this.productsService.productSignal);

  // TODO: Recibe el delivery date
  isOrderSkiped: WritableSignal<boolean> = signal(false);

  firebaseOrder = computed(() => this.signalStoreService.firebaseOrder());

  subscriptions$ = toObservable(this.signalStoreService.subscriptions);

  addableItems: Signal<any | null> = computed(() => this.getGroupedItems('addables'));
  premiumAddableItems: Signal<any | null> = computed(() => this.getGroupedItems('premiumAddables'));
  customBoxFlow: any
  deliveryInfo: Signal<any | null> = computed(() => this.getDeliveryInfo());
  items: any = signal(null)
  isLoadingContent = true;
  lockFrequencyButton = signal(false);
  lockSaveButton = false;
  product: any = signal(null)
  selectedFrequency: WritableSignal<any> = signal('')
  selectedStartDate: Signal<any | null> = computed(() => !this.deliveryInfo() ? '' : this.deliveryInfo()?.deliveryDate?.monthNumber + '/' + this.deliveryInfo()?.deliveryDate?.dayNumber + '/' + this.deliveryInfo()?.deliveryDate?.year)
  subTotalAmount = signal(0)
  totalAmount: Signal<number> = computed(() => this.getTotalAmount())
  taxesAmount: Signal<number> = computed(() => this.getTaxesAmount(this.product()?.bundle?.items))

  productId: any = signal(null)
  redirectTo!: string;
  bundleEditionType: WritableSignal<BundleEditionType> = signal(BundleEditionTypes.subscription);
  bundleEditionTypes = BundleEditionTypes;

  isLimitedUser = computed(() => this.signalStoreService.isLimitedUser());
  addressInfo: Signal<any | null> = computed(() => this.setUpAddressInfo());
  boxMaximumOrder = computed(() => {
    const bundle = this.product()?.bundle;
    return bundle.limited?.maximum || null;
  });
  boxMinimumOrder = computed(() => {
    const bundle = this.product()?.bundle;
    return bundle.limited?.minimum || null;
  });
  addedItemsQuantity = signal(0);
  boxPercentOfLimit = computed(() => (this.addedItemsQuantity() * 100) / (this.boxMaximumOrder() || this.addedItemsQuantity()))
  showLimitedMaximumOrder = computed(() => this.boxMaximumOrder() && (this.isLimitedUser() && (this.addedItemsQuantity() < this.boxMaximumOrder())));
  boxPercentOfMinLimit = computed(() => (this.addedItemsQuantity() * 100) / (this.boxMinimumOrder() || this.addedItemsQuantity()))
  showLimitedMinimumOrder = computed(() => this.boxMinimumOrder() && (this.isLimitedUser() && (this.addedItemsQuantity() < this.boxMinimumOrder())));
  frequencies$ = toObservable(this.signalStoreService.frequencies);
  isLimitedUser$ = toObservable(this.isLimitedUser);
  preventSetUp: boolean = false;

  shouldPreventRedirection: boolean = false;

  isCustomBoxSignupFlow = computed(() => this.signalsStoreService.isCustomBoxSignupFlow());

  permissions = computed(() => this.signalsStoreService.permissions());
  orderDeliveryDate = signal('');
  currentOdooOrder = computed(() => this.#getCurrentOdooOrder());

  isAbleToDiscardChanges = computed(() => this.#setUpDiscardChanges());

  hasSession = computed(() => this.signalStoreService.showCartPreview());

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  private insertAtBeginning(map: any, key: string, value: any) {
    let newMap = new Map();
    newMap.set(key, value);

    for (let [k, v] of map) {
      newMap.set(k, v);
    }

    return newMap;
  }


  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) return;
    this.setUpUrl();
  }

  ngOnDestroy(): void {
    this.localStorageService.remove(LOCALSTORAGE_KEYS.SIGNUP_CUSTOM_BOX);
  }

  private handleRouteChanges(): any {
    // Validations apply if product exists in firebaseOrder but not in odooOrder
    this.route.params
      .pipe(
        switchMap((params) => {

          const productId = params['productId'];
          if (productId) {
            this.productId.set(productId)
            return of(productId)
          }

          // Validate if the user is tagged as limited so we should get the productId from the subscription
          if (this.signalStoreService.isLimitedUser())
            return this.setUpLimitedUserInitialData();

          return of(null);
        }),
        filter((productId) => {
          // At this point, we should notify to user of an unexpected error and stop the pipes execution
          if (!productId)
            this.notificationService.show({ text: 'Failed to load the information. Please contact support for assistance.', type: 'warning' });
          return !!productId
        }),
        switchMap((productId) => {
          if (productId)
            return this.productsService.getProductById(productId, this.bundleEditionType()).pipe(map((res) => res.data as Product));
          return of(null);
        }),
        filter((product: any) => {
          if (!product)
            unableOperationMessage(this.modalContentService)
          return !!product;
        }),
        tap((product: Product) => {
          if (product) {
            const ids = this.#getBundleAndComponentsIdsForStock(product);
            this.stockService.getStock(undefined, ids)
          }
        }),
        switchMap((product: Product) =>
          combineLatest([
            this.productSignal$,
            this.odooOrder$,
            this.firebaseOrder$,
            this.stock$
          ])
        ),
        filter(([productFromOdoo, odooOrder, firebaseOrder, stock]) => {
          return !!productFromOdoo && !!stock?.length;
        }),
        switchMap(([productFromOdoo, odooOrder, firebaseOrder, stock]) => this.setUpInitialData(productFromOdoo, odooOrder, firebaseOrder, stock)),
        delay(1000),
        tap(() => this.isLoadingContent = false)
      )
      .subscribe();
  }

  #getBundleAndComponentsIdsForStock(product: Product): any[] {
    let ids = [product.id];

    const setProductIds = (currentData: number[], items: BundleItems[]) => {
      for (const item of items) {
        if (!item.productId) continue;
        currentData.push(item.productId)
      }
      return currentData;
    };

    if (product.bundle?.items.length)
      ids = [...setProductIds(ids, product.bundle.items)]
    if (product.bundle?.addables?.length)
      ids = [...setProductIds(ids, product.bundle.addables)]
    if (product.bundle?.premiumAddables?.length)
      ids = [...setProductIds(ids, product.bundle.premiumAddables)]

    return ids;
  }

  private setUpLimitedUserInitialData() {
    return combineLatest([this.subscriptions$, this.frequencies$, this.isLimitedUser$]).pipe(
      filter(([subscriptions, frequencies, isLimitedUser]) => (!!subscriptions && !!subscriptions.length && !!subscriptions[0].product?.id) && (!!frequencies && !!frequencies.length) && (typeof isLimitedUser === 'boolean')),
      map(([subscriptions, frequencies, isLimitedUser]) => {
        if (isLimitedUser) {
          const subscriptionFrequencyId = subscriptions[0].frequency;
          const subscriptionFrequency = frequencies.find(f => f.id === subscriptionFrequencyId);
          if (subscriptionFrequency) this.selectedFrequency.set(subscriptionFrequency);
        }
        return subscriptions[0].product.id
      }),
      tap((productId) => {
        if (productId)
          this.productId.set(productId);
      }),
      switchMap((productId) => of(productId))
    );
  }

  private setUpInitialData(productFromOdoo: any, odooOrder: any, firebaseOrder: any, stock: any) {

    this.isOrderSkiped.set(odooOrder?.isSkipped ?? false);
    if (this.preventSetUp) {
      return of(null)
    }
    let product: any;
    if (productFromOdoo)
      product = productFromOdoo;

    if (stock && stock.length) {
      product.variant = this.getVariant(product);

      // TODO: Obtenemos la orden del delivery date que llegó en el query params.

      if (this.currentOdooOrder()?.products?.subscription) {

        const odooOrderProduct = this.currentOdooOrder()?.products?.subscription
          .find((odooProduct: any) => odooProduct?.variant?.id === product?.variant?.id || odooProduct?.variant?.id === product?.variant?.variantId);

        if (odooOrderProduct) {
          this.lockFrequencyButton.set(true);
        }
      }

      const productFromFirebase = this.orderService.checkIfExistsProductInFirebaseOrder(product, firebaseOrder, this.bundleEditionType())
      if (productFromFirebase) {
        product = { ...product, ...productFromFirebase };
        product['existsInOrder'] = true;
      }

      const odooOrderProductData = this.currentOdooOrder()?.products?.[this.bundleEditionType()].find((odooProduct: any) => odooProduct?.variant?.id === product?.variant?.id || odooProduct?.variant?.id === product?.variant?.variantId);
      const odooOrderProductBundleItems = odooOrderProductData?.bundle?.items ?? null;
      if (this.shouldPreventRedirection && odooOrderProductBundleItems) {
        product.bundle.items = product.bundle.items.map((p: any) => {
          const fromOrder = odooOrderProductBundleItems.find((op: any) => op.id === p.id);
          return {
            ...p,
            quantity: fromOrder?.quantity ?? p.quantity
          }
        })
      }

    }

    if (product?.subscription?.frequency?.id) {
      this.selectedFrequency.set({
        ...product?.subscription.frequency,
        value: {
          id: product?.subscription.frequency?.id,
          name: product?.subscription.frequency?.name,
        }
      });
    } else if (!this.selectedFrequency() && this.signalStoreService.frequencies().length) {
      this.selectedFrequency.set(this.signalsStoreService.frequencies()[0]);
    }

    if ((odooOrder || firebaseOrder)) {
      const subscriptionFBOrderProductsMap = this.getOrderProductsMapByKey('subscription', 'id', firebaseOrder)
      const subscriptionOdooOrderProductsMap = this.getOrderProductsMapByKey('subscription', 'id', odooOrder)
      const oneTimeFBOrderProductsMap = this.getOrderProductsMapByKey('common', 'id', firebaseOrder)
      const oneTimeOdooOrderProductsMap = this.getOrderProductsMapByKey('common', 'id', odooOrder)

      const mapKey = product?.id;

      const existsInFBOrderSub = subscriptionFBOrderProductsMap.get(mapKey);
      const existsInOdooOrderSub = subscriptionOdooOrderProductsMap.get(mapKey);
      const existsInFBOrderOneTime = oneTimeFBOrderProductsMap.get(mapKey);
      const existsInOdooOrderOneTime = oneTimeOdooOrderProductsMap.get(mapKey);

      product['existsInOrder'] =
        !!(existsInFBOrderSub ||
          existsInOdooOrderSub ||
          existsInFBOrderOneTime ||
          existsInOdooOrderOneTime)
    }

    if (!(product.quantity > 0)) product.quantity = 1;

    this.product.set(product)
    this.items.set(this.getBundleItems('items'));
    this.updateAddedItemsQuantity(true);
    this.localStorageService.set('customBoxPrevious', product)
    const currentItems: Map<number, any> = this.items();
    const itemsIds = Array.from(currentItems.keys()).sort().join();
    this.localStorageService.set(this.product().id + '_BundleItems', itemsIds)
    this.subTotalAmount.set(this.getTotalPrice(product))
    return of(null);
  }

  private getOrderProductsMapByKey(productKeyType: string, mapKey: string, sourceOrder: any,) {
    if (!sourceOrder || !sourceOrder?.products?.[productKeyType]?.length)
      return new Map()

    const orderProductsMap = arrayToMap(mapKey, sourceOrder.products[productKeyType]);

    return orderProductsMap;
  }

  setUpUrl() {
    const fullUrl = this.router.url;
    const fullUrlSplitteed = fullUrl.split('/');
    const editionType = fullUrlSplitteed[3] as BundleEditionType;
    this.bundleEditionType.set(editionType);

    const customBoxFlowSelected = fullUrlSplitteed[1]?.toUpperCase();
    switch (customBoxFlowSelected) {
      case CUSTOM_BOX_FLOW.ORDER:
      case CUSTOM_BOX_FLOW.PRODUCT:
      case CUSTOM_BOX_FLOW.SHOP:
        this.redirectTo = '/order'
        this.orderDeliveryDate.set(this.route.snapshot.queryParamMap.get('deliveryDate') ?? '');
        break;
      case CUSTOM_BOX_FLOW.SIGNUP:
        this.redirectTo = '/shop'
        break;

      default:
        break;
    }

    this.handleRouteChanges();
  }

  private getDeliveryInfo() {
    if (!this.deliveriesService.deliveryZoneInfo()) return null

    const { deliveryDate, cutoffDate, cutoffTime, deliveryWindow, cutoffDay } = (this.deliveriesService.deliveryZoneInfo()?.order || this.deliveriesService.deliveryZoneInfo()) as DeliveryInformation;
    const deliveryDateFormatted = formatDateToReadableString(deliveryDate)
    const cutoffDateFormatted = formatDateToReadableString(cutoffDate)

    const deliveryPickupDateText = this.signalsStoreService.isAddressRequired() ? 'Delivery Date: ' : 'Pick Up Date: '
    return {
      deliveryDate: deliveryDateFormatted,
      cutoffDate: cutoffDateFormatted,
      cutoffTime,
      cutoffDay,
      deliveryWindow,
      deliveryDateText: `${deliveryPickupDateText}${deliveryDateFormatted.dayName} ${deliveryDateFormatted.month} ${deliveryDateFormatted.day}`,
      cutoffDateText: `${cutoffDateFormatted.dayName} ${cutoffDateFormatted.month} ${cutoffDateFormatted.day} at ${cutoffTime}`,
      thanksMessage: this.signalsStoreService.isAddressRequired() ? 'Get excited for your delivery, which will arrive on:' : 'Your order will be ready for pick-up on:'
    }
  }

  onChangeFrequency(target: any) {
    const frequencyId = target.value
    this.selectedFrequency.set(this.signalStoreService.frequencies().find(frequency => frequency.id === frequencyId))
  }

  onRemoveItem(item: any) {
    if (!this.validateLimitedUserBoxLimit((item.quantity * -1))) return;
    const categoryName = this.replaceSpacesByRegex(item.category.name)
    if (item.isPremiumAddon) {
      if (!this.premiumAddableItems().has(categoryName)) {
        const itemMap = new Map().set(item.id, item)
        this.premiumAddableItems().set(categoryName, itemMap)
      }
      else {
        const categoryAddableItems = this.premiumAddableItems().get(categoryName)
        categoryAddableItems.set(item.id, item)
      }
    } else {
      if (!this.addableItems().has(categoryName)) {
        const itemMap = new Map().set(item.id, item)
        this.addableItems().set(categoryName, itemMap)
      }
      else {
        const categoryAddableItems = this.addableItems().get(categoryName)
        categoryAddableItems.set(item.id, item)
      }
    }

    this.items()?.delete(item.id)
    this.updateAddedItemsQuantity();

    this.updateSubTotal();
  }

  onAddItem(item: any) {
    if (!this.validateLimitedUserBoxLimit(item.quantity)) return;
    const categoryAddableItems = item.isPremiumAddon ? this.premiumAddableItems().get(this.replaceSpacesByRegex(item.category.name)) : this.addableItems().get(this.replaceSpacesByRegex(item.category.name))

    this.items.set(this.insertAtBeginning(this.items(), item.id, item))
    this.updateAddedItemsQuantity();
    categoryAddableItems.delete(item.id)

    this.updateSubTotal();
  }

  private updateAddedItemsQuantity(firstTime: boolean = false) {
    const items = !this.items().size ? [] : Array.from(this.items().values())
    const quantity = items.reduce((total: number, currentItem: any) => {
      return total + currentItem.quantity;
    }, 0);
    this.addedItemsQuantity.set(quantity);
    if (firstTime)
      this.localStorageService.set(this.product().id + '_BundleItemsQuantities', quantity);
  }

  onChangeQuantity(item: any) {
    if (!this.validateLimitedUserBoxLimit(item.validationQuantity)) {
      this.items.set([]);
      setTimeout(() => {
        this.items.set(this.getBundleItems('items'))
      }, 1);

      return;
    }

    const currentItem = this.items().get(item.id);
    currentItem.quantity = item.quantity;

    this.updateSubTotal();
    this.updateAddedItemsQuantity();
  }

  private updateSubTotal() {
    this.product.update((product: any) => {
      const items = !this.items().size ? [] : Array.from(this.items().values())
      product.bundle.items = items
      this.subTotalAmount.set(this.getTotalPrice(product))

      return product
    })
  }

  private validateLimitedUserBoxLimit(newQuantity: number = 0): boolean {
    if (this.isLimitedUser() && this.boxMaximumOrder() && this.boxMinimumOrder()) {

      const maxMessage = "You've reached the maximum allowed number of items in this bundle.";
      const minMessage = "You've reached the minimum required number of items in this bundle."

      if (this.addedItemsQuantity() === this.boxMaximumOrder() && newQuantity >= 0) {
        this.notificationService.show({ text: maxMessage, type: 'warning' });
        return false
      }
      if (this.addedItemsQuantity() === this.boxMinimumOrder() && newQuantity < 0) {
        this.notificationService.show({ text: minMessage, type: 'warning' });
        return false
      }
      if ((this.addedItemsQuantity() + newQuantity) > this.boxMaximumOrder()) {
        this.notificationService.show({ text: maxMessage, type: 'warning' });
        return false
      }
      if (newQuantity < 0 && (this.addedItemsQuantity() + newQuantity) < this.boxMinimumOrder()) {
        this.notificationService.show({ text: minMessage, type: 'warning' });
        return false
      }
    }
    return true;
  }

  #setUpDiscardChanges() {
    const currentQuantity = this.addedItemsQuantity();
    const storedQuantity = this.localStorageService.get(this.product().id + '_BundleItemsQuantities');

    const currentItems: Map<number, any> = this.items();
    const currentItemsIds = Array.from(currentItems.keys()).sort().join();
    const storedItemsIds = this.localStorageService.get(this.product().id + '_BundleItems');

    return currentQuantity !== storedQuantity || currentItemsIds !== storedItemsIds;
  }

  undoChanges() {
    this.product.set(this.localStorageService.get('customBoxPrevious'))
    this.items.set(this.getBundleItems('items'));
    this.updateAddedItemsQuantity();
    this.updateSubTotal();
  }


  private getGroupedItems(key: string): Map<string, Map<string, any>> {
    const groupedItems = new Map<string, Map<string, any>>();

    if (!this.product()?.bundle?.[key].length)
      return groupedItems

    const stock = this.stock();

    this.product()?.bundle?.[key]?.forEach((item: any) => {
      const categoryName = this.replaceSpacesByRegex(item.category.name);
      if (!groupedItems.has(categoryName)) {
        groupedItems.set(categoryName, new Map());
      }

      if (stock?.length) {
        const stockData = stock.find((s: any) => s.variantId === item.id);
        item.stock = stockData;
      }

      groupedItems.get(categoryName)!.set(item.id, item);
    });

    return groupedItems;
  }

  getBundleItems(key: string) {
    const customBox = this.product()
    const customBoxItems = customBox?.bundle?.[key];

    return this.getItemsAsMap(customBox, customBoxItems);
  }

  private getItemsAsMap(array: any[], arrayItems: any[]) {
    const map = new Map();
    if (array && arrayItems?.length)
      for (const item of arrayItems) {
        map.set(item.id, item)
      }

    return map;
  }

  private saveItemsInMap(attributeKey: keyof CustomBoxComponent, array: any[], arrayItems: any[]) {
    if (array && arrayItems?.length)
      for (const item of arrayItems) {
        if (this?.[attributeKey])
          this[attributeKey].set(item.id, item)
      }
  }

  gotoCategory(category: string) {
    this.router.navigate(['/shop/' + (category || '').toLowerCase().replace(/\s/g, '-')]);
  }


  getMapValues(map: Map<string, Map<string, any>>, key: string) {
    const gettedMap = map.get(key) || new Map();
    return Array.from(gettedMap.values())
  }

  removeCharByRegex(text: string, char: string) {
    return text.replace(`/${char}/g`, "")
  }

  replaceSpacesByRegex(text: string) {
    return text.replace(`/ /g`, "_")
  }

  private getSubTotalAmount(product: number): number {
    return this.getTotalPrice(product);
  }

  private getTotalAmount(): number {
    return this.subTotalAmount() + this.taxesAmount();
  }

  private getTaxesAmount(items: any[]): number {
    if (!items || !items.length)
      return 0

    return items.reduce((total: number, product: any) => {
      let bundleTotalPriceWithoutTax = 0;
      let bundleTotalTax = 0;

      if (product?.bundle?.items?.length) {
        product.bundle.items.forEach((bundleItem: any) => {
          const itemPrice = (+bundleItem.price * +bundleItem.quantity)
          const itemTax = !bundleItem?.taxes ? 0 : +(bundleItem.taxes.reduce((total: number, item: any) =>
            total + +(item?.percentage)
            , 0) / 100).toFixed(2)

          bundleTotalPriceWithoutTax += itemPrice;
          bundleTotalTax += +(itemPrice * (itemTax)).toFixed(2);
        });
      }

      let productTaxPercent = 0

      if (!product?.bundle?.items?.length) {
        productTaxPercent = product.taxes.reduce((total: number, item: any) =>
          total + +(item?.percentage)
          , 0)
      }

      const productPrice = bundleTotalPriceWithoutTax
        ? bundleTotalPriceWithoutTax * +product.quantity
        : product.price
      const productTotalPrice = +productPrice * +product.quantity
      const productTaxAmount = +(+productTaxPercent * +productTotalPrice / 100).toFixed(2)
      const finalPrice = +(total + productTaxAmount + (bundleTotalTax * +product.quantity)).toFixed(2);

      return finalPrice;
    }, 0);
  }

  private getTotalPrice(product: any): number {
    let totalPrice = 0;
    if (!product?.bundle?.items?.length)
      return totalPrice

    if (product?.bundle?.isFixed) {
      // Fixed bundle:
      totalPrice = +product.price;
      const premiumItems = product?.bundle?.items?.filter((pi: any) => pi.isPremiumAddon);
      if (premiumItems?.length) {
        totalPrice += premiumItems.reduce((total: number, bundleItem: any) => {
          const bundleItemPrice = (+bundleItem?.price * +bundleItem?.quantity) || 0
          return total + bundleItemPrice
        }, 0);
      }
    } else if (product?.bundle?.items?.length) {
      // Cummulative bundle
      totalPrice = product.bundle.items.reduce((total: number, bundleItem: any) => {
        const bundleItemPrice = (+bundleItem?.price * +bundleItem?.quantity) || 0
        return total + bundleItemPrice
      }, 0);
    }

    if (totalPrice === 0)
      totalPrice = +product?.price

    return totalPrice //* (product?.quantity || 1);
  }

  private getVariantFromPackage(product: any) {
    let stock = this.stockService.stockSignal();
    if (!stock)
      return null;

    // WHEN BE A PACKAGE CASE, attribute key will be null
    const variant = stock.find((item: any) => {
      // WHEN the product already have variantId because it comes from buy-again or favorites:
      if (item.variantId === product?.variantId) return item.variantId;
      // WHEN a product has packages just needs validate the productId:
      if (item.productId === product?.id) return item.variantId;
    });

    variant.id = variant.variantId
    // const selectedAttribute = null;
    // variant.attribute = this.selectedAttribute() || null
    return variant ? variant : null
  }

  getVariant(product: any) {
    let stock = this.stockService.stockSignal();
    if (!stock)
      return null;

    const hasPackages: boolean = !!product?.packages?.length;
    if (hasPackages)
      return this.getVariantFromPackage(product)

    // WHEN BE A PACKAGE CASE, attribute key will be null
    const variant = stock.find((item: any) => {
      // WHEN the product already have variantId because it comes from buy-again or favorites:
      if (item.variantId === product?.variantId) return item.variantId;

      if (product?.id === item?.productId) {
        // WHEN we dont have attributes because is a product without variants.
        if (!item?.attribute?.id && !item?.attribute?.value?.id)
          return item.variantId
      }
    });
    if (variant)
      variant.id = variant?.variantId
    return variant ? variant : null
  }

  private getSubscriptionDate(deliveryDate: any) {
    const { year, monthNumber, dayNumber } = deliveryDate;

    return `${year}-${monthNumber}-${dayNumber}`
  }

  private getProduct(product: any) {
    const subscriptionDate = this.getSubscriptionDate(this.deliveryInfo()?.deliveryDate)
    const frequency = this.selectedFrequency();
    const subscription = !frequency ? null : {
      startDate: subscriptionDate || null,
      frequency
    }
    const packageItem = null; // for now, in this flow doesnt exists
    const size = null; // for now, in this flow doesnt exists
    const totalPrice = this.getSubTotalAmount(product)
    const variant = this.getVariant(product);
    // Addables items:
    const addablesItemsMap = !this.addableItems().size ? [] : Array.from(this.addableItems().values())
    const addablesItems = !addablesItemsMap.length ? [] : addablesItemsMap.map(
      (currentMap: any) => Array.from(currentMap.values())
    ).flat()
    // Premium addables items:
    const premiumAddablesItemsMap = !this.premiumAddableItems().size ? [] : Array.from(this.premiumAddableItems().values())
    const premiumAddablesItems = !premiumAddablesItemsMap.length ? [] : premiumAddablesItemsMap.map(
      (currentMap: any) => Array.from(currentMap.values())
    ).flat()

    const bundle = {
      ...product?.bundle,
      addables: addablesItems,
      premiumAddables: premiumAddablesItems,
      items: !this.items().size ? [] : [...Array.from(this.items().values())]
    }

    const quantity = product.quantity > 0 ? product.quantity : 1;
    const previousProduct = {
      ...product,
      bundle,
      quantity: this.localStorageService.get(LOCALSTORAGE_KEYS.SIGNUP_CUSTOM_BOX) || quantity,
      package: packageItem, // for now, in this flow doesnt exists
      size, // for now, in this flow doesnt exists
      subscription,
      totalPrice,
      variant
    }

    return this.getFirebaseProductOrder(previousProduct)
  }

  private getFirebaseProductOrder(product: any) {
    return {
      bundle: product?.bundle,
      category: product?.category,
      id: product?.id,
      img: product?.img,
      isSubscription: product?.isSubscription,
      isASubscription: this.bundleEditionType() === BundleEditionTypes.subscription,
      hasPremiumAddables: product?.bundle?.hasPremiumAddables,
      isFromCard: true,
      name: product?.name,
      package: product?.package || null,
      presentation: product?.presentation || null,
      price: parseInt(product?.price),
      quantity: product?.quantity,
      size: product?.size,
      subCategory: product?.category,
      subscription: product?.subscription,
      taxes: product?.taxes,
      totalPrice: product?.totalPrice,
      variant: product?.variant,
      bundleQuantityChanged: product?.bundleQuantityChanged || false,
      isPublishedBundle: product?.isPublishedBundle ?? false
    }
  }

  private validateVariant(product: any) {
    if (!product?.variant) {
      unableOperationMessage(this.modalContentService);

      return false
    }

    if (!product?.bundle && !product?.variant?.stock) {
      unableOperationMessage(this.modalContentService);
      return false
    }

    return true
  }

  saveAndSubscribe() {
    if (isANoDeliveryDayUser(this.localStorageService, this.modalContentService)) return;
    if (isANoPaymentMethodUser(this.localStorageService, this.modalContentService, this.activeModal)) return;
    this.lockSaveButton = true;
    const product = this.getProduct(this.product())
    if (!this.stockService.stockSignal()?.length || !this.validateVariant(product) || (this.bundleEditionType() === BundleEditionTypes.subscription && !this.selectedFrequency()))
      return;

    this.preventSetUp = true;
    const order: any = { products: { [this.bundleEditionType()]: [product] } };
    this.orderService.updateOrder({
      order,
      orderId: this.currentOdooOrder()?.id,
      showDefaultMessage: false,
      getStock: false,
      deliveryInfo: this.isLimitedUser() ? this.deliveryInfo() : undefined,
      isUpdatingBundle: true
    }, this.orderDeliveryDate()).pipe(
      tap(() => {
        this.lockSaveButton = false;

        // Signup flow
        const signupCustomBox = this.localStorageService.get(LOCALSTORAGE_KEYS.SIGNUP_CUSTOM_BOX);
        if (signupCustomBox) this.afterChangesSavedSignupFlow();

        let subKeyPathProducts = `orderProducts.products.${this.bundleEditionType()}`
        const updateData: any = {};
        updateData[subKeyPathProducts] = [product];
        this.#removeBundleFromFirebase(
          this.bundleEditionType(),
          product,
          updateData,
          subKeyPathProducts,
          this.bundleEditionType() === this.bundleEditionTypes.subscription
        )
      })
    ).subscribe();

  }

  #removeBundleFromFirebase(productKey: string, product: any, updateData: any, subKeyPathProducts: string, subscription: boolean) {
    const defaultFlow = () => {
      if (!this.isLimitedUser())
        this.router.navigate([this.redirectTo]);
    }

    const index = this.firebaseOrder()
      .findIndex(x => x.deliveryDate === this.orderDeliveryDate());

    if (index === -1)
      return defaultFlow();

    const productsGroup = this.firebaseOrder()[index]?.products?.[productKey as BundleEditionTypes] || [];
    if (!productsGroup.length) return defaultFlow();
    const productsGroupUpdated = this.removeProductFromArray(product, productsGroup);
    if (productsGroupUpdated.length !== productsGroup.length)
      updateData[subKeyPathProducts] = productsGroupUpdated;
    const payload = { updateData }
    this.orderService.editSubKeys(payload, this.orderDeliveryDate(), this.firebaseOrder());

    defaultFlow();
  }

  private removeProductFromArray(product: any, productList: any[]): any[] {
    return productList.filter((productItem) => productItem.package?.id && product.package?.id ? product.package.id !== productItem.package.id : productItem.variant.id !== product.variant.id);
  }


  private afterChangesSavedSignupFlow() {
    this.redirectTo = '/shop';
    this.localStorageService.set(LOCALSTORAGE_KEYS.NEW_ACCOUNT, true);
    this.localStorageService.remove(LOCALSTORAGE_KEYS.SIGNUP_CUSTOM_BOX);
    this.signalStoreService.isCustomBoxSignupFlow.set(false);
  }


  reloadPage(route: string) {
    this.signalsStoreService.signUpStep.set(2)
    this.location.replaceState(route);
    window.location.reload();
  }

  private setUpAddressInfo() {
    const session: Session = this.signalsStoreService.sessionSignal() as any;

    if (!session) return null;

    const { street, city, zip: zipCode, stateCode: state } = session.address;
    const { location, name } = session?.pickUpInfo || {};

    const isAddressRequired = this.signalsStoreService.isAddressRequired();
    return {
      title: isAddressRequired ? 'Delivery Address' : 'Pick Up Address',
      text: isAddressRequired ? [street, city, state, zipCode].join(', ') : `${name} ${location ? `at ${location}` : ''}`
    };
  }

  shouldDisableSaveButton() {
    let res = (this.bundleEditionType() === BundleEditionTypes.subscription && !this.selectedFrequency()) || !this.items().size || this.lockSaveButton;
    if (this.isLimitedUser())
      res = res || !this.isValidQuantityLimit();
    return res;
  }

  private isValidQuantityLimit() {
    const qty = this.addedItemsQuantity();
    const max = this.boxMaximumOrder() ?? qty;
    const min = this.boxMinimumOrder() ?? qty;
    return qty <= max && qty >= min;
  }

  unskipOrder() {

    const { id: orderId } = this.currentOdooOrder() ?? {};

    if (!orderId)
      return;

    this.orderService
      .skipOrder({
        orderId,
        donationAmount: null,
        isSkipping: false
      }, true, this.orderDeliveryDate()).pipe(
        tap(() => window.location.reload())
      ).subscribe();
  }

  #getCurrentOdooOrder(): OrderResponse | null {

    const index = this.orderService
      .odooOrder()
      .findIndex(x => x.deliveryInfo.deliveryDate === this.orderDeliveryDate());

    if (index === -1)
      return null;

    return this.orderService.odooOrder()[index];
  }
}

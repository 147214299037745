<app-notification></app-notification>
@if (product()) {
<div class="container-fluid main-container__full">
  <!-- Breadcrumbs -->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a (click)="gotoCategory()"><span class="material-symbols-outlined">home</span></a>
      </li>
      <li class="breadcrumb-item active" aria-current="page"><span class="fw-bold">
          {{ product()?.name }}
        </span></li>
    </ol>
  </nav>

  <!-- Product details -->
  <div class="row">
    <!-- Product Images -->
    <div class="col-lg-4">
      <div class="product__img">
        @if (isContentLoaded()) {
        <div class="product-card border-0 p-0" style="z-index: 10; top: 10px; right: 10px">
          <label [for]="'isFavorite'+product().id" class="custom-checkbox position-absolute w-100">
            <input class="opacity-0" type="checkbox" [id]="'isFavorite'+product().id"
              (change)="handleFavoriteChange($event)" [checked]="isFavorite()" />
            <i class="fa-heart fa-regular text-danger"></i>
            <i class="fa-heart fa-solid text-danger"></i>
          </label>
        </div>
        <app-carousel [items]="productImages()" [type]="'withThumbnail'"
          [showWhatsInsideButton]="product()?.bundle?.items?.length > 0"
          (onWhatsInsideClick)="whatsInside($event)"></app-carousel>
        }
      </div>
    </div>


    <div class="col-md-6 col-lg-4 pe-5">
      <!-- Product Info -->
      <div class="product__info">
        <!-- Subtitle -->
        <h6 class="product__producer">{{ product()?.producer?.name }}</h6>
        <!-- Title -->
        <h2 class="product__name">{{ product()?.name }}</h2>
        <!-- Favorites count -->
        <p class="product__fav-count"><i class="fa-solid fa-heart"></i> {{ likeTotals() }} {{likeTotals() > 1 ?
          'Favorites': 'Favorite'}}</p>
        <!-- Price Range -->
        @if(product()?.isBundle && !product()?.bundle?.items?.length && product()?.bundle?.priceRange) {
        <h2 class="product__price">{{product()?.bundle?.priceRange}}</h2>
        } @else {
        <!-- Price -->
        <h2 class="product__price">{{ (variant()?.price || product()?.price) | currency }}</h2>
        }

        <!-- Description -->
        <div class="product-desc-text" [innerHTML]="product()?.description.short"></div>

        <!-- Tags Icons -->
        <ul class="list-group list-group-horizontal">
          @for (tag of product()?.tags || []; track $index) {
          <li class="list-group-item pt-0 ps-0 tag-container">
            <img src="{{tag.icon || 'assets/images/product-placeholder.webp'}}" [alt]="tag.name">
            {{tag.name}}
          </li>
          }
        </ul>
      </div>
    </div>
    <!-- Product Summary -->
    <div class="col-md-6 col-lg-4">
      <div class="product__summary">
        @if (isContentLoaded()) {
        <app-product-card #productCard [card]="productCardSummary()"
          (selectedVariant)="onSelectedVariant($event)"></app-product-card>
        }
      </div>
    </div>
  </div>

  <div class="row mt-5 mb-5">
    <div class="col">
      <div class="accordion" id="accordionPanelsStayOpenExample">
        <!-- Product description -->
        @if(product().description.long) {
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#productDescription" aria-expanded="false" aria-controls="productDescription">
              Product Description
            </button>
          </h2>
          <div id="productDescription" class="accordion-collapse collapse">
            <div class="accordion-body" [innerHTML]="product()?.description.long"></div>
          </div>
        </div>
        }
        <!-- About the producer -->
        @if(product().producer?.description) {
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#aboutTheProducer" aria-expanded="false" aria-controls="aboutTheProducer">
              About the Producer
            </button>
          </h2>
          <div id="aboutTheProducer" class="accordion-collapse collapse">
            <div class="accordion-body">
              <div class="row">
                <div class="col-2 d-flex align-items-center">
                  <img src="{{product().producer?.img || 'assets/images/product-placeholder.webp'}}"
                    class="img-thumbnail rounded" alt="Producer description">
                </div>
                <div class="col-10" [innerHTML]="product()?.producer?.description">
                </div>
              </div>
            </div>
          </div>
        </div>
        }
        <!-- Ingredientes -->
        @if (product().description.ingredients) {
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#productIngredients" aria-expanded="false" aria-controls="productIngredients">
              Ingredients
            </button>
          </h2>
          <div id="productIngredients" class="accordion-collapse collapse">
            <div class="accordion-body" [innerHTML]="product()?.description.ingredients"></div>
          </div>
        </div>
        }
        <!-- Recipes -->
        @if(product().description.recipes) {
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#productRecipes" aria-expanded="false" aria-controls="productRecipes">
              Recipes
            </button>
          </h2>
          <div id="productRecipes" class="accordion-collapse collapse">
            <div class="accordion-body" [innerHTML]="product()?.description.recipes"></div>
          </div>
        </div>
        }
        <!-- Storage -->
        @if (product().description.storage) {
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#productStorage" aria-expanded="false" aria-controls="productStorage">
              Storage
            </button>
          </h2>
          <div id="productStorage" class="accordion-collapse collapse">
            <div class="accordion-body" [innerHTML]="product()?.description.storage"></div>
          </div>
        </div>
        }

      </div>
    </div>
  </div>

  <!-- You might also like -->
  @if(carouselItems()?.length) {
  <div class="row">
    @if (isContentLoaded()) {
    <div class="product-highlight">
      <h3 class="product-highlight__title">You might also like</h3>
      <div class="product-highlight__body">
        <app-carousel [items]="carouselItems()"></app-carousel>
      </div>
    </div>
    }
  </div>
  }
</div>
} @else {
<mat-progress-bar class="position-absolute rounded" mode="indeterminate" value="accent"></mat-progress-bar>
}
import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild, WritableSignal, computed, inject, signal } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { NgbModalRef, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalContentService } from '../../../shared/modal-content/modal-content.service';
import { ModalContentTypes } from '../../../shared/constants/modal-content-types';
import { AvailableDates, ModalContentData, PauseDates } from '../../../shared/modal-content/modal-content';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideNativeDateAdapter } from '@angular/material/core';
import { SubscriptionsService } from './subscriptions.service';
import { DeliveriesService } from '../deliveries/deliveries.service';
import { ChangeType, Subscription, UpdateSubscriptionPayload } from './subscriptions.types';
import { formatDateToReadableString } from '../../../shared/utils/formatting';
import { FormsModule } from '@angular/forms';
import { NotificationService } from '../../../shared/notification/notification.service';
import { LocalStorageService } from '../../../shared/local-storage.service';
import { FIREBASE_COLLECTIONS, LOCALSTORAGE_KEYS } from '../../../shared/constants/databases';
import { SignalsStoreService } from '../../../shared/signals-store.service';
import { tap } from 'rxjs';
import { ResolutionService } from '../../../shared/resolution.service';
import { RouterLink } from '@angular/router';
import { OrderService } from '../../../shared/order.service';
import { EmptyMessageComponent } from '../../../shared/empty-message/empty-message.component';
import { afterNextRender } from '@angular/core';
import { PreOrderedProduct } from '../../../product/product.types';
import { PreOrderedProductComponent } from './pre-ordered-product/pre-ordered-product.component';
import { environment } from '../../../../environments/environment';
import { Session } from '../../../shared/types/session.type';
import { depositConfig } from '../../../product/product.contstants';
import { SettingsService } from '../../settings.service';
import { DateTime } from 'luxon';
import { FirebaseCrudService } from '../../../shared/firebase-crud.service';

@Component({
  selector: 'app-subscriptions',
  standalone: true,
  providers: [provideNativeDateAdapter(), SubscriptionsService],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIcon,
    MatSelectModule,
    NgbModule,
    MatDatepickerModule,
    FormsModule,
    RouterLink,
    EmptyMessageComponent,
    PreOrderedProductComponent
  ],
  templateUrl: './subscriptions.component.html',
  styleUrl: './subscriptions.component.scss',
})
export class SubscriptionsComponent implements OnInit {
  @ViewChild('goToPause') goToPause!: ElementRef;

  private modalService = inject(ModalContentService);
  private subscriptionService = inject(SubscriptionsService);
  private signalStoreService = inject(SignalsStoreService);
  private deliveriesService = inject(DeliveriesService);
  private notificationService = inject(NotificationService);
  private localStorageService = inject(LocalStorageService);
  private resolutionService = inject(ResolutionService);
  private orderService = inject(OrderService);
  private firebaseCrudService = inject(FirebaseCrudService);
  #settingsService = inject(SettingsService);

  isWaitingForAPIResponse = computed(() => this.#settingsService.isWaitingForAPIResponse());

  subscriptions = computed(() => this.signalStoreService.subscriptions());
  frequencies = computed(() => this.signalStoreService.frequencies());
  deliveryZoneInfo = computed(() => this.deliveriesService.deliveryZoneInfo());
  globalPauseDate = computed(() => this.subscriptionService.globalPauseDate());
  isMobile = computed(() => this.resolutionService.isMobile());

  odooOrder = computed(() => this.orderService.odooOrder());
  isLimitedUser = computed(() => this.signalStoreService.isLimitedUser());
  isTemporaryRouteChange = signal(false);
  temporaryRouteChangeMessage = signal('');

  pauseButtonText = computed(() => this.#getPauseButtonText())

  pauseDates: PauseDates = {
    startDate: '',
    endDate: '',
  };

  emptyMessage = 'You do not have any subscriptions in your account. You can add subscriptions from the market.';

  //#region Pre Orders
  preorders: WritableSignal<PreOrderedProduct[]> = signal([]);
  preOrdersDisclaimer = signal(depositConfig.preorderedProductsDisclaimer);
  //#endregion

  constructor() {
    afterNextRender(() => {
      this.#validateTemporaryRoute()
    });
  }

  ngOnInit(): void {
    this.#getPreOrders();
    this.submitInitialReqs();
  }

  private submitInitialReqs() {
    this.subscriptionService.get();
  }

  openModalPause() {
    this.openModal(
      ModalContentTypes.PAUSE_SUBSCRIPTION,
      ChangeType.pause,
      true
    )?.closed.subscribe((result: PauseDates | null) => {
      if (!result) return;
      if (result.endDate && result.startDate) {
        this.subscriptionService.updateAll({
          datePauseStart: result.startDate,
          datePauseEnd: result.endDate,
          pause: true,
        });
      }
    });
  }

  openModalResume() {

    const subscriptions = this.subscriptions()

    this.modalService
      .openModal(
        ModalContentTypes.RESUME_SUBSCRIPTION,
        {
          title: 'Resume subscription',
          textContent: 'You are about to resume your subscription and it will take effect immediately.',
          resumeSubscriptions: {
            subscriptions
          }
        }
      ).closed.subscribe(result => {
        if (!result || !result.startDates || !result.startDates.length) return;
        this.subscriptionService.updateAll({ pause: false, startDates: result.startDates });
      });

  }

  openModalCancel(subscription: Subscription) {
    this.openModal(
      ModalContentTypes.CONFIRMATION,
      ChangeType.cancel,
      true,
      subscription
    )?.closed.subscribe((result: { confirm: boolean } | null) => {
      if (!result) return;
      if (result.confirm) {
        this.subscriptionService.cancel(subscription.id);
      }
    });
  }

  openModalSkip(subscription: Subscription) {
    this.openModal(
      ModalContentTypes.SKIP_DELIVERY,
      ChangeType.skip,
      true
    )?.closed.subscribe(
      (result: { skip: boolean; goToPause: boolean } | null) => {
        if (!result) return;
        if (result.skip) {

          // For limited users this skip will skip the entire irder

          const currenOddoOrder = this.odooOrder()
            .find(x => x.deliveryInfo.deliveryDate === this.deliveriesService.defaultDeliveryDate());

          if (this.isLimitedUser() && currenOddoOrder?.id) {
            this.orderService
              .skipOrder({ orderId: currenOddoOrder?.id, isSkipping: true, donationAmount: null }, false, currenOddoOrder.deliveryInfo.deliveryDate)
              .pipe(
                tap(() => this.subscriptionService.get())
              ).subscribe();
            return
          }

          this.updateSubscription(subscription.id, { skip: true }, false)
            .pipe(
              tap(() =>
                this.notificationService.show({
                  text: 'Your subscription has been skipped successfully',
                  type: 'success',
                })
              )
            )
            .subscribe();

        } else if (result.goToPause) {
          this.openModalPause();
        }
      }
    );
  }

  private openModal(
    actionType: ModalContentTypes,
    changeType: ChangeType,
    fullScreenOnMobile: boolean,
    subscription?: Subscription
  ): NgbModalRef | null {
    const modalContentData: ModalContentData = this.generateModalContentData(
      changeType,
      fullScreenOnMobile,
      subscription
    );
    return this.modalService.openModal(actionType, modalContentData);
  }

  generateModalContentData(
    changeType: ChangeType,
    fullScreenOnMobile: boolean,
    subscription?: Subscription
  ): ModalContentData {
    const isBeforeCutoff =
      this.deliveryZoneInfo()?.deliveryDate ===
      this.deliveryZoneInfo()?.prevDeliveryDate;


    let formattedDeliveryDate: any = this.deliveryZoneInfo()?.deliveryDate;
    if (formattedDeliveryDate) formattedDeliveryDate = formatDateToReadableString(formattedDeliveryDate).readableString

    switch (changeType) {
      case ChangeType.cancel:
        return {
          title: 'Are you sure?',
          textContent: subscription?.skipDate ? '' : isBeforeCutoff
            ? `<b>ATTENTION! </b>Your subscription cancellation for this product will take effect immediately.`
            : `<b>ATTENTION! </b>Unfortunately, it's too late to delete this product subscription for this week. If you proceed, your cancellation will take effect on your next delivery on <b>${formattedDeliveryDate}.</b>`,
          cancelButtonText: 'Keep subscription',
          confirmButtonText: 'Cancel subscription',
          fullScreenOnMobile,
        };
      case ChangeType.pause:

        const minDate = this.subscriptions().map(sub => {
          const [year, month_str, day] = sub.nextDelivery.split('-');
          const month = (+month_str) - 1;
          return {
            stringDate: sub.nextDelivery,
            timestamp: new Date(+year, +month, +day).getTime()
          };
        }).sort((a, b) => a.timestamp - b.timestamp)[0].stringDate;

        return {
          title: 'Pause subscription',
          textContent:
            'You are about to pause your subscription. Which deliveries would you like to skip?',
          fullScreenOnMobile,
          pauseSubscription: {
            minAvailableDate: minDate
          }
        };
      case ChangeType.resume:
        return {
          title: 'Resume subscription',
          textContent: `You are about to resume your subscription and it will take effect immediately. Delivery scheduled for ${formatDateToReadableString(
            this.deliveryZoneInfo()?.deliveryDate || ''
          ).readableString
            }`,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Resume subscription',
        };
      case ChangeType.skip:
        return {
          title: 'Wait a moment!',
          fullScreenOnMobile,
        };
      case ChangeType.update:
        return {
          title: 'Hold Up!',
          textContent:
            'You are making changes to your subscription quantity. These changes will take effect immediately.',
        };
      default:
        return {};
    }
  }

  private handleSelectionChange(
    subscription: Subscription,
    data: MatSelectChange,
    isDeliveryChange?: boolean
  ) {

    // No available to change the frequency because the route in your zone has a temporary change.
    if (this.isTemporaryRouteChange())
      return;

    // Open modal for available date selection:
    const availableDates: AvailableDates[] = subscription.dateAvailables?.map(e => {
      return {
        date: formatDateToReadableString(e).readableString,
        originalDate: e
      } as AvailableDates
    }) || []

    this.modalService.openModal(ModalContentTypes.SUBSCRIPTION_NEXT_DELIVERY, {
      title: 'When would you like your next delivery?',
      textContent: `${isDeliveryChange ? 'Please' : 'In order to update your frequency, please'} select the next date that you would like to receive <b>${subscription.product.name}</b>.`,
      subscriptionNextDelivery: {
        availableDates
      },
      closeable: false,
    }, { backdrop: 'static' }).closed.subscribe(
      (response: { confirm: boolean, date: string }) => {
        if (response?.confirm) {
          if (response.date) {
            let payload: any = {
              startDate: response.date,
              frequency: isDeliveryChange ? subscription.frequency : data.value
            };

            this.updateSubscription(subscription.id, payload, false)
              .pipe(
                tap(() =>
                  this.notificationService.show({
                    text: isDeliveryChange ? `The next delivery date for the product '${subscription.product.name}' has been updated successfully.` : 'Your subscription frequency has been successfully updated',
                    type: 'success',
                  })
                ),
                tap(() => this.orderService.getOrder(false))
              ).subscribe();
          } else {
            this.notificationService.show({ text: 'You should select a valid date', type: 'warning' })
            this.subscriptionService.restorePreviousValues(subscription);
          }
        } else {
          this.subscriptionService.restorePreviousValues(subscription);
        }
      }
    )
  }

  updateSubscription(
    subscriptionId: number,
    data: Partial<UpdateSubscriptionPayload>,
    showDefaultMessage: boolean = true
  ) {
    return this.subscriptionService.update(
      subscriptionId,
      data,
      showDefaultMessage
    );
  }

  validateSelectedDates() {
    if (!this.pauseDates.startDate || !this.pauseDates.endDate)
      return this.notificationService.show({
        text: 'You must select a start and end date to pause your subscription.',
        type: 'error',
      });
  }

  manageSubscriptions() {
    if (this.globalPauseDate().start) {
      this.openModalResume();
    } else {
      this.openModalPause();
    }
  }

  private handleQuantityChange(subscription: any, event: any) {
    const quantity = +event.target.value;
    this.updateSubscription(subscription.id, { quantity }, false)
      .pipe(
        tap(() =>
          this.notificationService.show({
            text: 'Your subscription quantity has been successfully updated',
            type: 'success',
          })
        )
      )
      .subscribe();
  }

  validateSubscriptionUpdate(params: {
    subscription: Subscription;
    event: any;
    isQuantity: boolean;
  }) {
    const avoidHoldUpMessage = this.signalStoreService.userPreferences().holdUpSubscriptions.value;

    if (avoidHoldUpMessage)
      return this.triggerUpdate(params);

    this.openModal(
      ModalContentTypes.HOLD_UP_UPDATE_SUBSCRIPTION,
      ChangeType.update,
      false
    )?.closed.subscribe(
      (result: { avoid: boolean; confirmChanges: boolean }) => {

        if (result?.avoid)
          this.#updateFirebasePreferences();

        if (result?.confirmChanges)
          this.triggerUpdate(params);
        else
          this.subscriptionService.restorePreviousValues(params.subscription);
      }
    );
  }

  triggerUpdate(params: {
    subscription: Subscription;
    event: any;
    isQuantity: boolean;
    isDeliveryChange?: boolean;
  }) {
    params.isQuantity
      ? this.handleQuantityChange(params.subscription, params.event)
      : this.handleSelectionChange(params.subscription, params.event, params.isDeliveryChange);
  }

  undoSkipLine(sub: Subscription) {
    this.modalService.openModal(ModalContentTypes.CONFIRMATION, {
      title: 'Change of Plans?',
      textContent: `You’re about to reinstate your delivery for ${sub.skipDate}. Would you like to receive your order as originally planned?`,
      cancelButtonText: 'Keep Skipped',
      confirmButtonText: 'Unskip Week',
    }).closed
      .subscribe((res: { confirm: boolean } | null) => {
        if (!res?.confirm) return;
        this.updateSubscription(sub.id, { unskip: true }, true).pipe(tap(() => this.orderService.getOrder(false))).subscribe();
      })
  }

  //#region Pre Orders
  #getPreOrders() {
    this.orderService.getPreOrders().pipe(
      tap((preOrders) => this.preorders.set(preOrders))
    ).subscribe();
  }
  //#endregion
  #validateTemporaryRoute() {
    const currentSessionInfo: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION);

    if (!currentSessionInfo)
      return;

    const { temporaryChange } = currentSessionInfo?.deliveryInfo ?? {};
    this.isTemporaryRouteChange.set(!!temporaryChange?.active);

    if (this.isTemporaryRouteChange())
      this.temporaryRouteChangeMessage.set(temporaryChange?.message ?? '');
  }

  #getPauseButtonText() {
    return this.isWaitingForAPIResponse() ? "IN PROGRESS..." : (this.globalPauseDate().start ? "REMOVE PAUSE" : "PAUSE SUBSCRIPTIONS")
  }

  afterPreOrderLineUpdated() {
    this.#getPreOrders();
  }
  //#endregion
  async #updateFirebasePreferences() {

    const userId = this.signalStoreService.sessionSignal()?.accountInfo.id;
    if (!userId) return;

    const preferences = this.signalStoreService.userPreferences();

    preferences.holdUpSubscriptions = {
      value: true,
      date: DateTime.utc().toMillis()
    };

    const exists = await this.firebaseCrudService.existsDoc(FIREBASE_COLLECTIONS.PREFERENCES, userId.toString());

    if (exists)
      this.firebaseCrudService.update(FIREBASE_COLLECTIONS.PREFERENCES, userId, preferences);
    else
      this.firebaseCrudService.add(FIREBASE_COLLECTIONS.PREFERENCES, userId, preferences);
  }
}
